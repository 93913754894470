import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import '../Schedulee/schedule.css'
import { FaUser, FaClock, FaMapMarkedAlt} from 'react-icons/fa';


// Path untuk gambar diubah ke format biasa; pastikan file gambar berada di path yang benar
import Author1 from '../Assets/author1.jpg';
import Author2 from '../Assets/author2.jpg';
import Author3 from '../Assets/author3.jpg';
import Author4 from '../Assets/author4.jpg';
import Author5 from '../Assets/author5.jpg';
import Author6 from '../Assets/author6.jpg';
import Shapes1 from '../Assets/shapes/2.png'
import Shapes3 from '../Assets/shapes/3.png'
import Shapes4 from '../Assets/shapes/4.png'

const EventSchedules = () => {
  return (
    <>
      <div className="schedule-area bg-image ptb-120" >
        <div className="container">
          <div className="section-title" >
            <h2>Schedule<b> Event</b></h2>
            <div className="bg-title" id="jadwal">Events</div>
                <a href="#daftar" className="btn btn-danger" >Daftar Sekarang</a>
            <div className="bar"></div>
          </div>

          <div className="row">
            <Swiper navigation={true} modules={[Navigation]} className="schedule-slides">
              <SwiperSlide className="col-lg-12 col-md-12">
                <div className="schedule-slides-item">
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                          Medan
                          <span>Kamis, 13 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author1} title="Ridho Awaluddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Free Workshop : Tips & Trik Membuat CV untuk Daftar Kampus Luar Negeri</a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Ridho Awaluddin</a> Branch Manager of NIEC Medan
                          </li>
                          <li>
                            <FaClock/> 16:00 - 20:00 WIB/ GMT (UTC) +7
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/Uir7gqh2UabnZPBA6" target="_blank" className="location"> Grand Mercure Maha Cipta Medan Angkasa - Medan</a>
                          </li>
                        </ul>
                          
                      </div>
                    </div>
                  </div>

                  {/* Schedule Item 2 */}
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                          Tangerang
                          <span>Sabtu, 15 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author2} title="Nasaruddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Free Workshop : Strategi Sukses Interview Beasiswa Kuliah Luar Negeri</a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Nasaruddin</a> Country Director of NIEC INDONESIA
                          </li>
                          <li>
                            <FaClock/> 15:00 - 20:00 WIB/ GMT (UTC) +7
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/6dixQ4u1focqxyRo6" target="_blank" className="location">Ibis Gading Serpong</a>
                          </li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>

                  {/* Schedule Item 3 */}
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                          Yogyakarta
                          <span>Minggu, 16 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author3} title="Nasaruddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Free Workshop : Ready With NIEC Indonesia : Study & Work Overseas </a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Darius Letardo</a> Branch Manager of NIEC Yogyakarta
                          </li>
                          <li>
                            <FaClock/> 15:00 - 20:00 WIB/ GMT (UTC) +7
                          </li>
                        </ul>
                        <ul>
                          <li>
                          <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/LY12wiZAHWiaxqt27" target="_blank" className="location">Grand Mercure Yogyakarta Adi Sucipto</a>
                          </li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>

                  {/* Schedule Item 3 */}
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                          Surabaya
                          <span>Jumat, 21 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author4} title="Nasaruddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Free Workshop : Test Kemampuan Bahasa Inggrismu, Gratis IELTS Test!</a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Duhita Nirmala Apsari</a> Branch Manager of NIEC Surabaya
                          </li>
                          <li>
                            <FaClock/> 16:00 - 20:00 WIB/ GMT (UTC) +7
                          </li>
                        </ul>
                        <ul>
                          <li>
                              <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/RqcUUJ9xMHEBv9iUA" target="_blank" className="location">Wyndham Hotel Surabaya
                              </a>
                            </li>
                        </ul>

                      </div>
                    </div>
                  </div>

                  {/* Schedule Item 3 */}
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                            Makassar
                          <span>Sabtu, 22 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author5} title="Nasaruddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Free Workshop : Ready With NIEC Indonesia : Study & Work Overseas !</a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Ardiansyah</a> Branch Manager of NIEC Makassar
                          </li>
                          <li>
                            <FaClock/> 15:00 - 20:00 WITA/ GMT (UTC) +8
                          </li>
                        </ul>
                        <ul>
                          <li>
                          <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/is3tJFzWUvwP9yeH8" target="_blank" className="location">Four Points by Sheraton - Makassar</a>
                          </li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>

                  {/* Schedule Item 3 */}
                  <div className="single-schedule">
                    <div className="schedule-date">
                      <div className="d-table">
                        <div className="d-table-cell">
                          Denpasar
                          <span>Minggu, 23 Feb 2025</span>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-content">
                      <div className="author">
                        <img className="img2" src={Author6} title="Nasaruddin" alt="schedule" width={150} height={150} />
                      </div>
                      <div className="schedule-info">
                        <h3>
                          <a href="#jadwal">Jangan Salah Pilih Jurusan Kuliah " Gratis Test Psikologi"</a>
                        </h3>
                        <ul>
                          <li>
                            <FaUser/> By{" "}
                            <a href="#jadwal">Leny Savitri</a> Corporate Human Resource of NIEC INDONESIA
                          </li>
                          <li>
                            <FaClock/> 15:00 - 20:00 WITA/ GMT (UTC) +8
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <FaMapMarkedAlt/> <a href="https://maps.app.goo.gl/NoqCZZDBmC7g1aiT8" target="_blank" className="location">Swiss BellHotel Rainforest Kuta - Bali</a>
                          </li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>


                  {/* ================= */}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* Decorative Shapes */}
        <div className="shape2 rotateme">
          <img src={Shapes1} alt="shape2" width={38} height={38} />
        </div>
        <div className="shape3 rotateme">
          <img src={Shapes3} alt="shape3" width={51} height={57} />
        </div>
        <div className="shape4">
          <img src={Shapes4} alt="shape4" width={29} height={29} />
        </div>
      </div>
    </>
  );
};

export default EventSchedules;
