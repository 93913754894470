import React, { useState, useEffect } from 'react';
import Logo from '../Assets/logo.png';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import '../Navbar/navbar.css';
import { FaCalendar, FaCompass } from 'react-icons/fa';
import Form from './FormAmbassador.jsx'
import Footer from '../Footer/footer.jsx'

const Ambassador = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
        {/* BERANDA
        ===================================================== */}
        <div className='banner'>
        <Navbar expand="lg" className={`sticky-navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
            <Container className='p-2'>
            <Navbar.Brand href="#home" className='logo'>
                <img src={Logo} alt="logo" className="img-fluid" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto hide">
                <Nav.Link className='pe-3 text-white' href="#beranda">Beranda</Nav.Link>
                <Nav.Link className='pe-3 text-white' href="#wef">Syarat & Ketentuan</Nav.Link>
                {/* <Nav.Link className='pe-3 text-white' href="#jadwal">Jadwal</Nav.Link> */}
                <Button href="#daftar" type='button' className='btn btn-danger btn-md'>Daftar Sekarang</Button>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className="container-fluid">
            <div className="main-banner-content banner-content-center">
                <p>
                    Jadilah Bagian Dari Kami & Dapatkan <span>Keuntungan Istimewa</span>
                </p>
                <h1>
                    Join Our WEF
                    <b> 2</b>
                    <b>0</b>
                    <b>2</b>
                    <b>5</b>
                    <br />
                    <span>Ambassador</span>
                </h1>

                <ul>
                    <li>
                    <FaCalendar /> Medan, Jakarta, Yogyakarta, Surabaya, Makassar, & Denpasar
                    </li>
                    <li>
                    <FaCompass /> 13 - 23 Feb 2025
                    </li>
                </ul>

                <div className="row d-flex justify-content-center">
                    <div className="col-lg-5">
                        <a href="#daftar" className="btn3 btn-danger btn-lg button1">Daftar Sekarang</a>
                    </div>
                </div>
            </div>
        </div>
        </div>

        {/* SYARAT & KETENTUAN
        ===================================================== */}
        <div className="about-area-two  bg-image2" id="wef">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="about-content">
                <h3 className='text-center pb-4'>Benefit Join Ambassador</h3>
                <div className="benefit">
                  <ul className="pricing-content">
                    <li className='pb-1'>Mendapat bonus sebesar IDR 10k/ peserta</li>
                    <li className='pb-1'>Bonus bisa dicairkan ketika peserta berhasil "daftar & hadir" saat event WEF</li>
                    <li className='pb-1'>Untuk bisa mencairkan bonus, Ambassador wajib mencari minimal 10 peserta dan maksimal 25 peserta.</li>
                    <li className='pb-1'>Bonus akan ditransfer paling lambat 1 minggu setelah event WEF melalui rekening yang sudah dicantumkan.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
                <div className="about-content">
                    <h3 className='text-center pb-4'>Syarat dan Ketentuan </h3>
                    <div className="benefit">
                        <ul className="pricing-content">
                            <li className='pb-1'>Minimal usia 18th</li>
                            <li className='pb-1'>Mendaftar pada <a href="#daftar">formulir</a> di bawah ini</li>
                            <li className='pb-1'>Mendapatkan kode refferal untuk diberikan ke peserta saat mendaftar WEF.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="about-content">
                    <h3 className='text-center pb-4'>JobDesk </h3>
                    <div className="benefit">
                        <ul className="pricing-content">
                            <li className='pb-1'>Mendatangkan peserta event WEF</li>
                            <li className='pb-1'>Kriteria peserta WEF yang dicari adalah usia 17 - 25th dan berminat kuliah ke luar negeri</li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4">
                <a href="https://drive.google.com/drive/folders/1A9nvraHGSKrd0t_FlZai4HCLPuIpPuap" target='blank' className="btn3 btn-danger btn-lg button1 text-center">Download Poster</a>
            </div>
        </div>
        </div>
        </div>
        
        <Form />
        <Footer />
    </>
    
  );
};

export default Ambassador;
