import { Routes, Route } from "react-router-dom";
import App from './App';
import Attendance from './Attendance/Attendance.jsx';
import Ambassador from './Ambassador/Ambassador.jsx';


const RouteEdufest = () => {
    return (
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/attendance' element={<Attendance />} />
                <Route path='/ambassador' element={<Ambassador />} />
            </Routes>
    )
}

export default RouteEdufest