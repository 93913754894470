import axios from 'axios'

const url = 'https://superstudent.niecindonesia.com/api/v1/application';
const superStudentV1 = 'https://superstudent.niecindonesia.com/api/v1/';
const urlEvent = 'https://superstudent.niecindonesia.com/event';
const id_event = 199;

const baseUrl =  axios.create({
    baseURL: url,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const superAppsV1 =  axios.create({
    baseURL: superStudentV1,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`
    }
})

const eventUrl =  axios.create({
    baseURL: urlEvent,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`
    }
})

const API_URL = {
    superAppsV1,
    baseUrl,
    eventUrl,
    id_event
}

export default API_URL