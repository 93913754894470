import React, { Component } from 'react';
import { Button, Container, Row, Col, FormGroup, Label } from "reactstrap";
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from "react-hook-form";
import API_URL from '../API'
import Swal from 'sweetalert2';
import "react-phone-input-2/lib/bootstrap.css";
import '../Form/form.css'

class FormAmbass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nama_lengkap: "",
            email: "",
            alamat: "",
            domisili: "",
            no_wa: "",
            no_ktp: "",
            no_rek: "",
            akun_ig: "",
            akun_sosmed: "",
            errors: [],
            btnDisabled: false,
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    validateField = (field, message) => {
        const value = this.state[field];
        const errors = { ...this.state.errors };
        if (value === "" || value === 0) {
            errors[field] = message;
        } else {
            errors[field] = '';
        }
        this.setState({ errors });
    }

    handleValidation() {
        let formIsValid = true;
        const fields = [
            { name: "nama_lengkap", message: 'Nama Lengkap Harus Diisi' },
            { name: "email", message: 'Email Harus Diisi' },
            { name: "alamat", message: 'Alamat Harus Diisi' },
            { name: "domisili", message: 'Domisili Harus Diisi' },
            { name: "no_wa", message: 'No. Whatsapp Harus Diisi' },
            { name: "no_ktp", message: 'No. KTP Harus Diisi' },
            { name: "no_rek", message: 'No. Rekening Harus Diisi' },
            { name: "akun_ig", message: 'Akun Instagram Harus Diisi' },
            { name: "akun_sosmed", message: 'Akun Sosmed Harus Diisi' }
        ];

        fields.forEach(field => {
            this.validateField(field.name, field.message);
            if (this.state.errors[field.name]) formIsValid = false;
        });

        return formIsValid;
    }

    sendData() {
        var formData = new FormData();
        formData.append('id_event', API_URL.id_event);
        formData.append('nama_lengkap', this.state.nama_lengkap);
        formData.append('email', this.state.email);
        formData.append('alamat', this.state.alamat);
        formData.append('domisili', this.state.domisili);
        formData.append('no_wa', this.state.no_wa);
        formData.append('no_ktp', this.state.no_ktp);
        formData.append('no_rek', this.state.no_rek);
        formData.append('akun_ig', this.state.akun_ig);
        formData.append('akun_sosmed', this.state.akun_sosmed);

        API_URL.eventUrl.post('world-education-festival/ambassador/register', formData)
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    html:
                        'Selamat Anda Sudah Terdaftar Sebagai'+ '<br/>' +
                        'Ambassador World Education Festival 2025'+ '<br/>' +
                        'Berikut Adalah Kode Referal Anda'+ '<br/>' +
                        '<h2>' + response.data.data.kode_expo_ambassador + '</h2>'
                }).then((result) => {
                    window.location.reload()
                })
            })
            .catch(err => {
                if (err.response.status === 422 || err.response.status === 409) {
                    Swal.fire({
                        icon: 'error',
                        html:
                            'Maaf Ada Kesalahan' + '<br/>' +
                            err.response.data.data.nama_lengkap + '<br/>' +
                            err.response.data.data.email + '<br/>' +
                            err.response.data.data.no_wa + '<br/>' +
                            err.response.data.data.no_ktp + '<br/>' +
                            err.response.data.data.no_rek + '<br/>'
                    })
                }
            });
    }

    handleSubmit = () => {
        if (this.handleValidation()) {
            this.sendData();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Form Tidak Lengkap',
                text: 'Silakan lengkapi semua data yang diperlukan'
            });
        }
    }

    render() {
        return (
            <div style={{ maxWidth: '630px' }} className="container">
                <div className="subscribe-area .area">
                    <h2>REGISTER WEF AMBASSADOR</h2>
                </div>
                <div className="row" id="daftar">
                    <div className="col-lg-12">
                        <form className="form">
                            <div className="jarak">
                                <label htmlFor="nama_lengkap" style={{ fontWeight: '500' }}>Nama Lengkap</label>
                                <input
                                    className={`form-control ${this.state.errors.nama_lengkap ? "is-invalid" : ""}`}
                                    name="nama_lengkap"
                                    type="text"
                                    id="nama_lengkap"
                                    onChange={this.handleInput}
                                />
                                {this.state.errors.nama_lengkap && <div className="invalid-feedback">{this.state.errors.nama_lengkap}</div>}
                            </div>

                            <div className="jarak">
                                <label htmlFor="email" style={{ fontWeight: '500' }}>Email</label>
                                <input
                                    className={`form-control ${this.state.errors.email ? "is-invalid" : ""}`}
                                    name="email"
                                    type="email"
                                    id="email"
                                    onChange={this.handleInput}
                                />
                                {this.state.errors.email && <div className="invalid-feedback">{this.state.errors.email}</div>}
                            </div>

                            <div className="jarak">
                                <label htmlFor="alamat" style={{ fontWeight: '500' }}>Alamat</label>
                                <input 
                                    className={`form-control ${this.state.errors.alamat ? "is-invalid" : ""}`}
                                    name="alamat"
                                    type="text" 
                                    id="alamat"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateAlamat}
                                />
                                {this.state.errors.alamat && <div className="invalid-feedback">{this.state.errors.alamat}</div>}
                            </div>
        
                            <div className="jarak">
                                <label htmlFor="domisili" style={{ fontWeight: '500' }}>Domisili</label>
                                <input 
                                    className={`form-control ${this.state.errors.domisili ? "is-invalid" : ""}`}
                                    name="domisili"
                                    type="text" 
                                    id="domisili"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateDomisili}
                                />
                                {this.state.errors.domisili && <div className="invalid-feedback">{this.state.errors.domisili}</div>}
                            </div>
        
                            <div className="jarak">
                                <label htmlFor="no_wa" style={{ fontWeight: '500' }}>No. Whatsapp</label>
                                <PhoneInput
                                    country={'id'} // default country
                                    value={this.state.no_wa}
                                    onChange={phone => this.setState({ no_wa: phone })}
                                    inputClass={`form-control ${this.state.errors.no_wa ? "is-invalid" : ""}`}
                                    inputStyle={{ width: '100%', height: '45px' }}
                                />
                                {this.state.errors.no_wa && <div className="invalid-feedback">{this.state.errors.no_wa}</div>}
                            </div>

        
                            <div className="jarak">
                                <label htmlFor="no_ktp" style={{ fontWeight: '500' }}>No. KTP</label>
                                <input 
                                    className={`form-control ${this.state.errors.no_ktp ? "is-invalid" : ""}`}
                                    name="no_ktp"
                                    type="text" 
                                    id="no_ktp"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateNoKtp}
                                />
                                {this.state.errors.no_ktp && <div className="invalid-feedback">{this.state.errors.no_ktp}</div>}
                            </div>
        
                            <div className="jarak">
                                <label htmlFor="no_rek" style={{ fontWeight: '500' }}>No. Rekening & Nama Bank</label>
                                <input 
                                    className={`form-control ${this.state.errors.no_rek ? "is-invalid" : ""}`}
                                    name="no_rek"
                                    placeholder="contoh (BCA/1234567890/Adrian)"
                                    type="text" 
                                    id="no_rek"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateNoRek}
                                />
                                {this.state.errors.no_rek && <div className="invalid-feedback">{this.state.errors.no_rek}</div>}
                            </div>
        
                            <div className="jarak">
                                <label htmlFor="akun_ig" style={{ fontWeight: '500' }}>Akun Instagram</label>
                                <input 
                                    className={`form-control ${this.state.errors.akun_ig ? "is-invalid" : ""}`}
                                    name="akun_ig"
                                    type="text" 
                                    id="akun_ig"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateAkunIg}
                                />
                                {this.state.errors.akun_ig && <div className="invalid-feedback">{this.state.errors.akun_ig}</div>}
                            </div>
        
                            <div className="jarak">
                                <label htmlFor="akun_sosmed" style={{ fontWeight: '500' }}>Akun Media Sosial Lainnya</label>
                                <input 
                                    className={`form-control ${this.state.errors.akun_sosmed ? "is-invalid" : ""}`}
                                    name="akun_sosmed"
                                    type="text" 
                                    id="akun_sosmed"
                                    onChange={this.handleInput}
                                    onBlur={(e) => this.validateAkunSosmed}
                                />
                                {this.state.errors.akun_sosmed && <div className="invalid-feedback">{this.state.errors.akun_sosmed}</div>}
                            </div>

                            <Button
                                className="btn3 btn-danger btn-md"
                                onClick={this.handleSubmit}
                                disabled={this.state.btnDisabled}
                                >
                                Submit
                                </Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormAmbass;
